import request from '@/utils/request'
// 获取可分配卡券接口
export function fetchAllotCards (query) {
  return request({
    url: '/api/mall/admin/allot/get/card/list',
    method: 'get',
    params: query
  })
}
// 获取库存操作历史列表
export function fetchRepertories (query) {
  return request({
    url: '/api/mall/admin/use/history/get/list',
    method: 'get',
    params: query
  })
}
// 获取分配列表
export function fetchAllots (query) {
  return request({
    url: '/api/mall/admin/allot/get/list',
    method: 'get',
    params: query
  })
}
// 修改分配库存接口
export function setAllotQuantity (data) {
  return request({
    url: '/api/mall/admin/allot/edit/quantity',
    method: 'post',
    data
  })
}
// 获取分配详情接口（派发列表）
export function fetchIssues (query) {
  return request({
    url: '/api/mall/admin/issue/get/list',
    method: 'get',
    params: query
  })
}
// 分配卡券接口
export function setAllot (data) {
  return request({
    url: '/api/mall/admin/allot/add',
    method: 'post',
    data
  })
}
// 获取加油券列表接口
export function fetchRefuels (query) {
  return request({
    url: '/api/mall/admin/refuel/get/list',
    method: 'get',
    params: query
  })
}
// 分配卡券编辑接口
export function editAllot (data) {
  return request({
    url: '/api/mall/admin/allot/edit',
    method: 'post',
    data
  })
}
// 实时消耗查询
export function stockQuery (data) {
  return request({
    url: '/api/test/stock/detail/handle',
    method: 'post',
    data
  })
}
// 消耗明细下载接口
export function fetchStocks (data) {
  return request({
    url: '/api/test/stock/handle',
    method: 'post',
    data
  })
}
// 获取图文广告列表
export function fetchAdvs (query) {
  return request({
    url: '/api/mall/admin/area/advert/get/list',
    method: 'get',
    params: query
  })
}
// 删除图文广告
export function delAdv (data) {
  return request({
    url: '/api/mall/admin/area/advert/deletes',
    method: 'post',
    data
  })
}
// 编辑网点主页广告接口
export function setAdv (data) {
  return request({
    url: '/api/mall/admin/area/advert/edit',
    method: 'post',
    data
  })
}
// 获取活动列表
export function fetchActivies (query) {
  return request({
    url: '/api/mall/admin/get/activity/list',
    method: 'get',
    params: query
  })
}
// 删除活动
export function delActivity (data) {
  return request({
    url: '/api/mall/admin/delete/activities',
    method: 'post',
    data
  })
}
// 根据活动id和关键字搜索商户
export function fetchMerchantsByKey (query) {
  return request({
    url: '/api/mall/admin/merchant/search/with/activity/id',
    method: 'get',
    params: query
  })
}
// 根据商户号获取列表
export function searchMerchantList (data) {
  return request({
    url: '/api/mall/admin/merchant/search/ids/with/nums',
    method: 'post',
    data
  })
}
// 关联商户
export function relateMerchants (data) {
  return request({
    url: '/api/mall/admin/relate/merchants',
    method: 'post',
    data
  })
}
// 获取代金券列表接口
export function fetchVouchers (query) {
  return request({
    url: '/api/mall/admin/coupon/get/list',
    method: 'get',
    params: query
  })
}
// 编辑活动
export function setActivity (data) {
  return request({
    url: '/api/mall/admin/edit/activity',
    method: 'post',
    data
  })
}
// 获取活动详情
export function getActivity (query) {
  return request({
    url: '/api/mall/admin/get/activity/detail',
    method: 'get',
    params: query
  })
}
// 获取员工列表接口（员工派红包）
export function fetchPacketEmployees (query) {
  return request({
    url: '/api/mall/admin/marketer/marketer/get/list',
    method: 'get',
    params: query
  })
}
// 删除员工接口（员工派红包）
export function delPacketEmployees (data) {
  return request({
    url: '/api/mall/admin/marketer/marketer/deletes',
    method: 'post',
    data
  })
}
// 添加/编辑员工(可能并不需要，因为有导入)
export function setPacketEmployee (data) {
  return request({
    url: '/api/mall/admin/marketer/marketer/edit',
    method: 'post',
    data
  })
}
// 获取活动下网点列表
export function fetchCarNets (query) {
  return request({
    url: '/api/mall/admin/car/area/get/list',
    method: 'get',
    params: query
  })
}
// 获取活动网点下客户信息（带搜索功能）
export function fetchCarCustomers (query) {
  return request({
    url: '/api/mall/admin/car/customer/get/list',
    method: 'get',
    params: query
  })
}
// 编辑车主活动下网点
export function setCarNet (data) {
  return request({
    url: '/api/mall/admin/car/area/edit',
    method: 'post',
    data
  })
}
// 删除活动下网点
export function detCarNet (data) {
  return request({
    url: '/api/mall/admin/car/area/delete',
    method: 'post',
    data
  })
}
// 特邀活动列表
export function fetchGuests (query) {
  return request({
    url: '/api/mall/admin/guest/get/list',
    method: 'get',
    params: query
  })
}
// 编辑添加活动
export function setGuest (data) {
  return request({
    url: '/api/mall/admin/guest/edit/activity',
    method: 'post',
    data
  })
}
// 删除活动
export function deGuest (data) {
  return request({
    url: '/api/mall/admin/guest/delete',
    method: 'post',
    data
  })
}
export function getGuest (query) {
  return request({
    url: '/api/mall/admin/guest/getActivityDetails',
    method: 'get',
    params: query
  })
}
// 特邀奖励获取活动列表
export function fetchAwards (query) {
  return request({
    url: '/api/mall/admin/guestReward/get/list',
    method: 'get',
    params: query
  })
}
// 编辑特邀商户奖励活动
export function setReward (data) {
  return request({
    url: '/api/mall/admin/guestReward/edit/activity',
    method: 'post',
    data
  })
}
// 删除特邀商户奖励活动
export function detReward (data) {
  return request({
    url: '/api/mall/admin/guestReward/delete',
    method: 'post',
    data
  })
}
// 领取数据列表
export function fetchRewardRecords (query) {
  return request({
    url: '/api/mall/admin/guestReward/get/getReceiveList',
    method: 'get',
    params: query
  })
}
// 获取调研活动详情
export function getReward (query) {
  return request({
    url: '/api/mall/admin/guestReward/get/getActivity',
    method: 'get',
    params: query
  })
}
// 获取调研活动下网点
export function fetchResearchNets (query) {
  return request({
    url: '/api/mall/admin/research/area/get/list',
    method: 'get',
    params: query
  })
}
// 编辑调研活动下网点
export function setResearchNet (data) {
  return request({
    url: '/api/mall/admin/research/area/edit',
    method: 'post',
    data
  })
}
// 删除活动下网点
export function delResearchNet (data) {
  return request({
    url: '/api/mall/admin/research/area/delete',
    method: 'post',
    data
  })
}
// 获取调研活动下网点下客户信息（带搜索功能）
export function fetchResearchCustomers (query) {
  return request({
    url: '/api/mall/admin/research/customer/get/list',
    method: 'get',
    params: query
  })
}
// 兑换调研活动网点下客户
export function convertCustomer (data) {
  return request({
    url: '/api/mall/admin/research/customer/convert',
    method: 'post',
    data
  })
}
// 预约活动查看数据
export function fetchAppointCustomer (query) {
  return request({
    url: '/api/mall/admin/appointment/customer/get/list',
    method: 'get',
    params: query
  })
}
// 预约活动后台编辑预约数据
export function setAppointCustomer (data) {
  return request({
    url: '/api/mall/admin/appointment/customer/post/editData',
    method: 'post',
    data
  })
}
// 预约活动后台编辑题目
export function setAppointTopic (data) {
  return request({
    url: '/api/mall/admin/appointment/customer/post/editTopic',
    method: 'post',
    data
  })
}
// 预约活动后台获取题目详情
export function getAppointTopic (query) {
  return request({
    url: '/api/mall/admin/appointment/customer/get/getTopic',
    method: 'get',
    params: query
  })
}

// 预约活动后台获取题目列表
export function fetchAppointTopics (query) {
  return request({
    url: '/api/mall/admin/appointment/customer/get/getTopicsList',
    method: 'get',
    params: query
  })
}
// 预约活动后台删除题目
export function delAppointTopic (data) {
  return request({
    url: '/api/mall/admin/appointment/customer/post/delTopicsList',
    method: 'post',
    data
  })
}
// 获取红包客户记录列表接口
export function fetchPacketCoustomers (query) {
  return request({
    url: '/api/mall/admin/envelope/customer/get/list',
    method: 'get',
    params: query
  })
}
// 编辑客户记录接口
export function setPacketCoustomer (data) {
  return request({
    url: '/api/mall/admin/envelope/customer/edit',
    method: 'post',
    data
  })
}
// 删除客户记录接口
export function delPacketCoustomer (data) {
  return request({
    url: '/api/mall/admin/envelope/customer/deletes',
    method: 'post',
    data
  })
}
// 获取黑名单列表接口(抽奖码模式)
export function fetchPacketBlacks (query) {
  return request({
    url: '/api/mall/admin/envelope/black/get/list',
    method: 'get',
    params: query
  })
}
// 添加/编辑黑名单接口(抽奖码模式)
export function setPacketBlack (data) {
  return request({
    url: '/api/mall/admin/envelope/black/edit',
    method: 'post',
    data
  })
}
// 删除黑名单接口(抽奖码模式)
export function delPacketBlack (data) {
  return request({
    url: '/api/mall/admin/envelope/black/deletes',
    method: 'post',
    data
  })
}
// 根据代金券id和关键字搜索商户
export function searchWithVoucherId (query) {
  return request({
    url: '/api/mall/admin/merchant/search/with/coupon/id',
    method: 'get',
    params: query
  })
}
// 代金券关联商户接口
export function relateVoucherMerchants (data) {
  return request({
    url: '/api/mall/admin/coupon/relate/merchants',
    method: 'post',
    data
  })
}
// 生成优惠券
export function creatVoucher (data) {
  return request({
    url: '/api/mall/admin/gen/wx/coupons',
    method: 'post',
    data
  })
}
// 编辑代金券详情
export function setVoucher (data) {
  return request({
    url: '/api/mall/admin/coupon/post/editDetail',
    method: 'post',
    data
  })
}
// 获取卡券列表
export const fetchCoupons = async (params) => {
  return request({
    url: '/api/mall/admin/voucher/get/list',
    method: 'get',
    params
  })
}
// 卡券设置
export function setCoupon (data) {
  return request({
    url: '/api/mall/admin/voucher/add',
    method: 'post',
    data
  })
}
// 获取抽奖活动数据
export const fetchLuckyRecords = async (params) => {
  return request({
    url: '/api/mall/admin/lucky/get/GetLuckyLog',
    method: 'get',
    params
  })
}
// 抽奖活动数据统计
export const fetchStatisticals = async (params) => {
  return request({
    url: '/api/mall/admin/lucky/get/statisticals',
    method: 'get',
    params
  })
}
// 获取礼品列表
export const fetchGifts = async (params) => {
  return request({
    url: '/api/mall/admin/gift/get/list',
    method: 'get',
    params
  })
}
// 获取当前活动下所有网点(根据活动网点来的list_前缀)
export const fetchActivityAreas = async (params) => {
  return request({
    url: '/api/mall/admin/get/activity/areas',
    method: 'get',
    params
  })
}
// 添加/编辑礼品接口
export function setGift (data) {
  return request({
    url: '/api/mall/admin/gift/edit',
    method: 'post',
    data
  })
}
// 删除礼品接口
export function delGift (data) {
  return request({
    url: '/api/mall/admin/gift/deletes',
    method: 'post',
    data
  })
}
// 获取礼品详情接口
export const getGift = async (params) => {
  return request({
    url: '/api/mall/admin/gift/detail',
    method: 'get',
    params
  })
}
// 获取礼品订单列表
export const fetchGiftOrders = async (params) => {
  return request({
    url: '/api/mall/admin/gift/order/get/list',
    method: 'get',
    params
  })
}
// 核销订单
export function giftOrderVerify (data) {
  return request({
    url: '/api/mall/admin/gift/order/verify',
    method: 'post',
    data
  })
}
// 获取活动限制区域列表接口
export const fetchLimits = async (params) => {
  return request({
    url: '/api/mall/admin/get/activity/restricted/area/list',
    method: 'get',
    params
  })
}
// 活动添加限制区域接口
export function setLimit (data) {
  return request({
    url: '/api/mall/admin/add/activity/restricted/areas',
    method: 'post',
    data
  })
}
// 活动删除限制区域接口
export function delLimit (data) {
  return request({
    url: '/api/mall/admin/delete/activity/restricted/areas',
    method: 'post',
    data
  })
}
// 获取领取记录接口
export const fetchSendPacketRecords = async (params) => {
  return request({
    url: '/api/mall/admin/marketer/receive/get/list',
    method: 'get',
    params
  })
}
// 员工活动表单详情
export const getSendPacketFormConfig = async (params) => {
  return request({
    url: '/api/mall/admin/marketer/marketer/getFormConfig',
    method: 'get',
    params
  })
}
// 员工活动表单编辑
export function setSendPacketFormConfig (data) {
  return request({
    url: '/api/mall/admin/marketer/marketer/editFormConfig',
    method: 'post',
    data
  })
}
// 获取加油券列表接口
export const fetchRefuel = async (params) => {
  return request({
    url: '/api/mall/admin/refuel/get/list',
    method: 'get',
    params
  })
}
// 获取中石化加油券列表接口
export const fetchSinopec = async (params) => {
  return request({
    url: '/api/mall/admin/refuel/get/sinopec/list',
    method: 'get',
    params
  })
}
// 添加加油券接口
export function setRefuel (data) {
  return request({
    url: '/api/mall/admin/refuel/add',
    method: 'post',
    data
  })
}
// 加油券重置库存接口
export function resetRefuel (data) {
  return request({
    url: '/api/mall/admin/refuel/reset/quantity',
    method: 'post',
    data
  })
}
// 获取白名单列表
export const fetchWhites = async (params) => {
  return request({
    url: '/api/mall/admin/public/get/whitelistList',
    method: 'get',
    params
  })
}
// 删除白名单
export function delWhite (params) {
  return request({
    url: '/api/mall/admin/public/get/delWhitelist',
    method: 'get',
    params
  })
}
// 编辑白名单
export function setWhite (data) {
  return request({
    url: '/api/mall/admin/public/post/editWhitelist',
    method: 'post',
    data
  })
}
// 公开活动编辑表单
export function setPublicForm (data) {
  return request({
    url: '/api/mall/admin/public/edit/editFormConfig',
    method: 'post',
    data
  })
}
// 获取表表单配置
export function getPublicForm (params) {
  return request({
    url: '/api/mall/admin/public/get/getFormConfig',
    method: 'get',
    params
  })
}
// 查看数据
export function getPubliRecords (params) {
  return request({
    url: '/api/mall/admin/public/get/rewardList',
    method: 'get',
    params
  })
}
// 查看详情
export function getPubliRecord (params) {
  return request({
    url: '/api/mall/admin/public/get/rewardDetails',
    method: 'get',
    params
  })
}
// 添加白名单
export function addPublicWhite (data) {
  return request({
    url: '/api/mall/admin/public/post/addWhitelist',
    method: 'post',
    data
  })
}

// 集团会员活动查看数据
export function getMemberLuckyRecord (params) {
  return request({
    url: '/api/mall/admin/crmActivitys/get/getRegistersList',
    method: 'get',
    params
  })
}
// 数据详情
export function getMemberLuckyRecordDetail (params) {
  return request({
    url: '/api/mall/admin/crmActivitys/get/getLogPrize',
    method: 'get',
    params
  })
}
// 卡套列表
export function getCardTrickList (params) {
  return request({
    url: '/api/mall/admin/cards/get/getCardLists',
    method: 'get',
    params
  })
}
// 获取卡套详情
export function getCardDetail (params) {
  return request({
    url: '/api/mall/admin/cards/get/getCardDetails',
    method: 'get',
    params
  })
}
// 删除卡套
export function delCardKey (data) {
  return request({
    url: '/api/mall/admin/cards/post/delCard',
    method: 'post',
    data
  })
}

// 新建卡套
export function setCardKey (data) {
  return request({
    url: '/api/mall/admin/cards/post/editCard',
    method: 'post',
    data
  })
}
// 卡密列表
export function getCardKeyList (params) {
  return request({
    url: '/api/mall/admin/cards/get/getCardKeyLists',
    method: 'get',
    params
  })
}
// 网点列表（会员预购）
export function getMemberAppointmentNetworkList (params) {
  return request({
    url: '/api/mall/admin/appointment/gift/get/networkList',
    method: 'get',
    params
  })
}
// 查看数据（会员预购）
export function getMemberAppointmentRecord (params) {
  return request({
    url: '/api/mall/admin/appointment/gift/get/getUserList',
    method: 'get',
    params
  })
}
// 员工管理列表（集团活动）
export function getMemberLuckyManagers (params) {
  return request({
    url: '/api/mall/admin/crmActivitys/get/getMarketerList',
    method: 'get',
    params
  })
}
// 添加，编辑（集团活动）
export function setMemberLuckyManager (data) {
  return request({
    url: '/api/mall/admin/crmActivitys/post/editMarketer',
    method: 'post',
    data
  })
}
// 删除员工（会员预购）
export function delMemberLuckyManager (data) {
  return request({
    url: '/api/mall/admin/crmActivitys/post/delMarketer',
    method: 'post',
    data
  })
}
// 网点列表
export function getMemberAppointmentNets (params) {
  return request({
    url: '/api/mall/admin/appointment/gift/get/networkList',
    method: 'get',
    params
  })
}
// 删除网点
export function delMemberAppointmentNet (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/delNetwork',
    method: 'post',
    data
  })
}
// 奖品列表
export function getMemberAppointmentGoods (params) {
  return request({
    url: '/api/mall/admin/appointment/gift/get/getPrizeList',
    method: 'get',
    params
  })
}
// 添加/编辑奖品
export function setMemberAppointmentGood (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/editPrize',
    method: 'post',
    data
  })
}
// 删除商品
export function detMemberAppointmentGood (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/delPrize',
    method: 'post',
    data
  })
}
// 编辑网点选项
export function setMemberAppointmentNet (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/editNetwork',
    method: 'post',
    data
  })
}
// 订单列表
export function getOrderList (params) {
  return request({
    url: '/api/mall/admin/appointment/gift/get/getOrderList',
    method: 'get',
    params
  })
}
// 编辑预约数据
export function setMemberAppointmentUser (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/editUser',
    method: 'post',
    data
  })
}
export function orderRefund (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/refund',
    method: 'post',
    data
  })
}

// 活动查询
// 获取活动列表（包含搜索）
export function querySearh (params) {
  return request({
    url: '/api/mall/admin/statistic/search',
    method: 'get',
    params
  })
}
// 获取活动详情
export function queryDetail (params) {
  return request({
    url: '/api/mall/admin/statistic/get/detail',
    method: 'get',
    params
  })
}
// 获取统计数据值接口(最上方五个数)
export function queryBudget (params) {
  return request({
    url: '/api/mall/admin/statistic/search/budget',
    method: 'get',
    params
  })
}
// 编辑活动接口
export function querySetActivity (data) {
  return request({
    url: '/api/mall/admin/statistic/edit/activity',
    method: 'post',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    data
  })
}
// 删除活动接口
export function queryDelActivity (data) {
  return request({
    url: '/api/mall/admin/statistic/delete/activity',
    method: 'post',
    data
  })
}
// 创建活动接口
export function queryAddActivity (data) {
  return request({
    url: '/api/mall/admin/statistic/create/activity',
    method: 'post',
    headers: {
      'Content-type': 'multipart/form-data'
    },
    data
  })
}
// 添加代金券接口
export function queryAddVoucher (data) {
  return request({
    url: '/api/mall/admin/statistic/create/stock',
    method: 'post',
    data
  })
}
// 修改网点余额接口
export function alterBalance (data) {
  return request({
    url: '/api/mall/admin/statistic/alter/area/balance',
    method: 'post',
    data
  })
}
// 获取网点余额修改历史记录接口
export function balanceLog (params) {
  return request({
    url: '/api/mall/admin/statistic/get/alter/area/balance/list',
    method: 'get',
    params
  })
}
// 刷新预算接口
export function refreshBudget (data) {
  return request({
    url: '/api/mall/admin/statistic/refresh/budget',
    method: 'post',
    data
  })
}
// 保存修改预算接口
export function updateBudget (data) {
  return request({
    url: '/api/mall/admin/statistic/update/budget',
    method: 'post',
    data
  })
}
// 修改扣除项接口
export function alterDeduct (data) {
  return request({
    url: '/api/mall/admin/statistic/alter/area/deduct',
    method: 'post',
    data
  })
}
// 获取网点扣除项修改历史记录接口
export function deductLog (params) {
  return request({
    url: '/api/mall/admin/statistic/get/alter/area/deduct/list',
    method: 'get',
    params
  })
}
// 实时消耗数据查询接口
export function searchQuery (params) {
  return request({
    url: '/api/mall/admin/statistic/search/real/statistic',
    method: 'get',
    params
  })
}
// 红包补发
export function againReceive (data) {
  return request({
    url: '/api/mall/admin/crmActivitys/post/againReceiveEnvelope',
    method: 'post',
    data
  })
}
// 员工派红包补发接口
export function marketerAgainReceive (data) {
  return request({
    url: '/api/mall/admin/marketer/marketer/againReceive',
    method: 'post',
    data
  })
}
// 抽奖活动红包补发
export function luckyReceiveEnvelope (data) {
  return request({
    url: '/api/mall/admin/lucky/post/receiveEnvelope',
    method: 'post',
    data
  })
}

// 问卷活动列表
export function questionLuckyList (params) {
  return request({
    url: '/api/mall/mini/askCoupon/marketer/get/activity/list',
    method: 'get',
    params
  })
}
// 问卷活动编辑奖品
export function questionLuckySetPrize (data) {
  return request({
    url: '/api/mall/admin/questionnaire/prize/edit',
    method: 'post',
    data
  })
}
// 问卷活动奖品列表
export function questionLuckyPrizes (params) {
  return request({
    url: '/api/mall/admin/questionnaire/prize/list',
    method: 'get',
    params
  })
}

// 问卷活动删除奖品
export function questionLuckyDelPrize (data) {
  return request({
    url: '/api/mall/admin/questionnaire/prize/delete',
    method: 'post',
    data
  })
}
// 问卷活动编辑问卷
export function questionLuckySetTopic (data) {
  return request({
    url: '/api/mall/admin/questionnaire/topic/edit',
    method: 'post',
    data
  })
}
// 问卷活动问卷列表
export function questionLuckyTopics (params) {
  return request({
    url: '/api/mall/admin/questionnaire/topic/list',
    method: 'get',
    params
  })
}
// 问卷活动删除问卷
export function questionLuckyDelTopic (data) {
  return request({
    url: '/api/mall/admin/questionnaire/topic/delete',
    method: 'post',
    data
  })
}

// 问卷活动查看数据
export function questionLuckyLogs (params) {
  return request({
    url: '/api/mall/admin/questionnaire/log/list',
    method: 'get',
    params
  })
}
// 问卷活动查看数据
export function questionLuckyStatisticals (params) {
  return request({
    url: '/api/mall/admin/questionnaire/log/statisticals',
    method: 'get',
    params
  })
}

// 答题问卷奖品列表
export function questionnairePrizes (params) {
  return request({
    url: '/api/mall/admin/questionnaire/answer/prize/list',
    method: 'get',
    params
  })
}
// 答题问卷编辑奖品
export function questionnaireSetPrize (data) {
  return request({
    url: '/api/mall/admin/questionnaire/answer/prize/edit',
    method: 'post',
    data
  })
}
// 答题问卷删除奖品
export function questionnaireDelPrize (data) {
  return request({
    url: '/api/mall/admin/questionnaire/answer/prize/delete',
    method: 'post',
    data
  })
}
// 答题问卷编辑题目
export function questionnaireSetTopic (data) {
  return request({
    url: '/api/mall/admin/questionnaire/answer/topic/edit',
    method: 'post',
    data
  })
}
// 答题问卷题目列表
export function questionnaireTopics (params) {
  return request({
    url: '/api/mall/admin/questionnaire/answer/topic/list',
    method: 'get',
    params
  })
}
// 答题问卷删除题目
export function questionnaireDelTopic (data) {
  return request({
    url: '/api/mall/admin/questionnaire/answer/topic/delete',
    method: 'post',
    data
  })
}

// 预约购更新网点信息
export function memberAppointmentupdateNetwork (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/update/network/name',
    method: 'post',
    data
  })
}
// 物流列表
export function memberAppointmentLogisticsList (params) {
  return request({
    url: '/api/mall/admin/appointment/gift/get/logisticsList',
    method: 'get',
    params
  })
}
// 编辑收货地址
export function memberAppointmentEditAddress (data) {
  return request({
    url: '/api/mall/admin/appointment/gift/post/editAddress',
    method: 'post',
    data
  })
}
// 专区资格客户查询
export function prefectureQuery (params) {
  return request({
    url: '/api/mall/admin/select/lucky/custAct',
    method: 'get',
    params
  })
}
// 提现记录列表
export function extractLogs (params) {
  return request({
    url: '/api/mall/admin/drawal/log/list',
    method: 'get',
    params
  })
}
// 提现记录统计
export function extractStatistics (params) {
  return request({
    url: '/api/mall/admin/drawal/log/statistics',
    method: 'get',
    params
  })
}

// 业务活动列表
export function businessList (params) {
  return request({
    url: '/api/mall/admin/business/recommend/activity/list',
    method: 'get',
    params
  })
}
// 删除业务活动
export function delBusiness (data) {
  return request({
    url: '/api/mall/admin/business/recommend/activity/delete',
    method: 'post',
    data
  })
}

// 业务活动查看数据
export function businessRecord (params) {
  return request({
    url: '/api/mall/admin/business/recommend/activity/user/list',
    method: 'get',
    params
  })
}

// 添加编辑业务活动
export function setBusiness (data) {
  return request({
    url: '/api/mall/admin/business/recommend/activity/edit',
    method: 'post',
    data
  })
}

// 业务活动详情
export function businessDetail (params) {
  return request({
    url: '/api/mall/admin/business/recommend/activity/details',
    method: 'get',
    params
  })
}

// 信翔支付宝活动列表
export function sutPayList (params) {
  return request({
    url: '/api/mall/admin/xingxiang/activity/data/list',
    method: 'get',
    params
  })
}

// 抽奖活动编辑收货地址
export function luckyEditAddress (data) {
  return request({
    url: '/api/mall/admin/lucky/post/editLog',
    method: 'post',
    data
  })
}
// 抽奖修改网点
export function luckyChangeNet (data) {
  return request({
    url: '/api/mall/admin/lucky/post/editLuckyLogOpenArea',
    method: 'post',
    data
  })
}
// 抽奖活动补充话费
export function luckySupplement (data) {
  return request({
    url: '/api/mall/admin/lucky/post/recharge',
    method: 'post',
    data
  })
}
// 物流列表
export function luckyLogisticsList (params) {
  return request({
    url: '/api/mall/admin/lucky/get/logistics_list',
    method: 'get',
    params
  })
}
// 抽奖活动删除白名单
export function luckyDelWhite (data) {
  return request({
    url: '/api/mall/admin/lucky/delete/white',
    method: 'post',
    data
  })
}
// 抽奖活动白名单地址
export function luckyWhiteList (params) {
  return request({
    url: '/api/mall/admin/lucky/get/whites/list',
    method: 'get',
    params
  })
}
// 获取抽奖活动卡套id列表
export function getLuckyCardIds (params) {
  return request({
    url: '/api/mall/admin/lucky/get/GetLuckyCardIds',
    method: 'get',
    params
  })
}
// 一键补发
export function batchRecharge (data) {
  return request({
    url: '/api/mall/admin/questionnaire/phone/batch/recharge',
    method: 'post',
    data
  })
}

// 单个一键补发
export function batchOneRecharge (data) {
  return request({
    url: '/api/mall/admin/questionnaire/phone/recharge',
    method: 'post',
    data
  })
}

// 一键补发
export function luckyBatchRecharge (data) {
  return request({
    url: '/api/mall/admin/lucky/phone/batch/recharge',
    method: 'post',
    data
  })
}
